import React, {useEffect, useState} from 'react';
import './App.css';
import Spinner from "./Spinner";
import LoadedApp from "./LoadedApp";


type EventData = {
    eventId: string;
    imageFilename: string;
    message: string;
    title: string;
}


function preloadImage(src: string) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function () {
            resolve(img)
        }
        img.onerror = img.onabort = function () {
            reject(src)
        }
        img.src = src
    });
}


function App() {
    const [eventData, setEventData] = useState<EventData | undefined>(undefined);

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventId = url.pathname.match(/^\/event\/([\w-_]+)$/)![1];

        const inner = async () => {
            const resp = await fetch("/api/getEvent", {
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    event: eventId,
                }),
            });
            const data = await resp.json();
            await preloadImage(`/pagestatic/${data.imageFilename}`);
            setEventData(data);
        }

        // noinspection JSIgnoredPromiseFromCall
        inner();
    }, []);


    return (
        <div className="wrapper">
            <div className="content">
                <div className="prefooter">
                    {eventData === undefined ? (
                        <>
                            <div className="title">Loading</div>
                            <Spinner/>
                        </>
                    ) : (
                        <LoadedApp {...eventData} />
                    )}
                </div>

                <footer>
                    <div>Event Photos</div>
                    <div>Icons by <a target="_blank" href="https://icons8.com">Icons8</a></div>
                </footer>
            </div>
        </div>
    );
}

export default App;
