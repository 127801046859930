import React, {useCallback, useRef, useState} from 'react';
import {List} from "immutable";
import Spinner from "./Spinner";

enum ProcessState {
    SETUP,
    UPLOADING,
    DONE,
}

type ImageUpload = {
    file: File;
    objectUrl: string;
}

function LoadedApp(props: {
    eventId: string;
    imageFilename: string;
    message: string;
    title: string;
}) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [processState, setProcessState] = useState<ProcessState>(ProcessState.SETUP);
    const [uploads, setUploads] = useState<List<ImageUpload>>(() => List());
    const [message, setMessage] = useState<string>("");
    const [nUploaded, setNUploaded] = useState<number>(0);

    const onFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const newUploads: Array<ImageUpload> = Array.from(files).map((f) => {
                return {
                    file: f,
                    objectUrl: URL.createObjectURL(f),
                }
            });

            setUploads((prev) => prev.concat(...newUploads));
        }
    }, []);

    const onClickChoose = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        fileInputRef.current?.click();
    }, [fileInputRef]);

    const onClickSend = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setProcessState(ProcessState.UPLOADING);

        // Get upload instructions for the batch from the API
        const batchResp = await fetch("/api/createBatch", {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                event: props.eventId,
                message: message,
                images: uploads.toArray().map((u) => ({
                    filename: u.file.name,
                }))
            }),
        });
        const batchConfig: {
            batchId: string;
            images: Array<{
                presign: {
                    url: string;
                    fields: Record<string, string>;
                }
            }>;
        } = await batchResp.json();

        await Promise.all(uploads.toArray().map(async (u, i) => {
            const presign = batchConfig.images[i].presign;
            const data = new FormData();
            Object.entries(presign.fields).map(([k, v]) => data.append(k, v));
            data.append("file", u.file);

            await fetch(presign.url, {
                method: "POST",
                body: data,
            });

            setNUploaded((p) => p + 1);
        }));

        setProcessState(ProcessState.DONE);
    }, [props.eventId, uploads, message]);

    return (
        <>
            <header>
                <div className="title">{props.title}</div>
                <img src={`/pagestatic/${props.imageFilename}`} className="mainphoto"/>
            </header>

            {processState === ProcessState.SETUP && (
                <>
                    <div className="message">{props.message}</div>
                    <div className="form">
                        <div>
                            <button onClick={onClickChoose}>
                                <span>{uploads.size === 0 ? "Choose Photos" : "Add More Photos"}</span>
                                <img src={process.env.PUBLIC_URL + "/icons8-photo-48.png"}/>
                            </button>
                        </div>
                        <input type="file" accept="image/*" multiple={true} onChange={onFileChange}
                               style={{display: "none"}} ref={fileInputRef}/>
                        {uploads.size > 0 && (
                            <>
                                <div className="preview-images">
                                    {uploads.map((u) => (
                                        <img key={u.objectUrl} src={u.objectUrl} />
                                    ))}
                                </div>
                                <div className="message-wrapper">
                                    <textarea placeholder="Add a message (optional)" value={message}
                                              onChange={(e) => setMessage(e.target.value)}/>
                                </div>
                                <div>
                                    <button onClick={onClickSend}>
                                        <span>Send Photos</span>
                                        <img src={process.env.PUBLIC_URL + "/icons8-send-48.png"}/>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            {processState === ProcessState.UPLOADING && (
                <>
                    <p style={{fontWeight: "600"}}>Uploading, please don't exit this app.</p>
                    <Spinner />
                    <p>{nUploaded} / {uploads.size}</p>
                </>
            )}

            {processState === ProcessState.DONE && (
                <>
                    <p style={{fontWeight: "600"}}><img src={process.env.PUBLIC_URL + "/icons8-tick-48.png"} style={{verticalAlign: "text-bottom"}} /> Done!</p>
                    <p>If you'd like to send more photos later, refresh the page.</p>
                </>
            )}
        </>
    );
}

export default LoadedApp;
